<template>
    <div class="head">
        <!-- <router-link to="/">
            <img src="../../assets/logo.jpg" alt="">
        </router-link> -->
        <a href="https://www.tjbwg.com/cn/Index.aspx">
                <img src="../../assets/logo.jpg" alt="">
        </a>
        <div class="nav">
            <!-- <img src="../../assets/icon1.png" class="nav-img nav-img-sq" alt="">
            <a v-for="(item, index) in navhead" :key="'as' + index" :href="item.path" :class="[index == 3 && 'activenav']">{{item.name}}</a> -->
            <img src="../../assets/0.png" height="55px" style="margin-top: 36px" alt="">
            
            <router-link to="/"  style="display: block;margin-top: 10px; position: relative; right: -10%;">
            <img src="../../assets/sy.png"  height="45" width="90" alt="">
            </router-link>
            <!-- <div class="head-img">
                <img src="../../assets/right.jpg" alt="">
                
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'heads',
    data() {return {
        navhead: [
                {path: 'https://www.tjbwg.com/cn/Index.aspx', name: '首页'},
                {path: 'https://www.tjbwg.com/cn/course.aspx', name: '概述'},
                {path: 'https://www.tjbwg.com/cn/NewsList.aspx', name: '动态'},
                {path: 'https://www.tjbwg.com/cn/collection.aspx', name: '典藏'},
                {path: 'https://www.tjbwg.com/cn/Exhibition.aspx', name: '展览'},
                {path: 'https://www.tjbwg.com/cn/activityList.aspx', name: '活动'},
                {path: 'https://www.tjbwg.com/cn/PublicationsList.aspx', name: '研究'},
                {path: 'https://www.tjbwg.com/cn/traffic.aspx', name: '服务'},
                {path: 'https://www.tjbwg.com/cn/mailbox.aspx', name: '互动'},
                {path: 'https://www.tjbwg.com/cn/AnniversaryIndex.aspx', name: '百年'}
            ],
    }}
}
</script>
<style scoped>
    .head {
        height: 130px;
        display: flex;
        padding: 0 3%;
        justify-content: space-between;
        border-bottom: 2px solid #790000;
    }
    .nav {
        height: 120px;
        line-height: 120px;
        width: 70%;
        display: flex;
        justify-content: space-around;
    }
    .nav a {
        font-size: 20px;
        color: #000;
        font-weight: 600;
    }
    .nav-img-sq {
        width: 47px;
        height: 39px;
        margin-top: 40px;
    }
    .nav a.activenav {
        color: #790000;
    }
</style>