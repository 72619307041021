<template>
    <div class="foot">
        <div class="footer footer1">
            <!-- <div class="mauto clearfix">
                <div class="link_a">
                    <div class="item"><h5>概述</h5><a href="https://www.tjbwg.com/cn/course.aspx">天博沿革</a><br><a href="https://www.tjbwg.com/cn/about.aspx?TypeId=10921">天博简介</a><br><a href="https://www.tjbwg.com/cn/about.aspx?TypeId=10922">馆长致辞</a><br><a href="https://www.tjbwg.com/cn/team.aspx">领导成员</a><br><a href="https://www.tjbwg.com/cn/about.aspx?TypeId=10924">组织机构</a><br></div>
                    <div class="item"><h5>展览</h5><a href="https://www.tjbwg.com/cn/Exhibition.aspx">常设展览</a><br><a href="https://www.tjbwg.com/cn/ExhibitionList.aspx?TypeId=10938">主题展览</a><br><a href="https://www.tjbwg.com/cn/ExhibitionList.aspx?TypeId=10939">临时展览</a><br><a href="https://www.tjbwg.com/cn/ExhibitionList.aspx?TypeId=10940">展览回放</a><br></div>
                    <div class="item"><h5>典藏</h5><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10929">玉器</a><br><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10930">青铜器</a><br><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10931">瓷器</a><br><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10932">书画</a><br><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10933">玺印</a><br><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10934">文房用具</a><br><a href="https://www.tjbwg.com/cn/collection.aspx?TypeId=10935">革命文物</a><br></div>
                    <div class="item"><h5>活动</h5><a href="https://www.tjbwg.com/cn/activityList.aspx">活动预告</a><br><a href="https://www.tjbwg.com/cn/Events.aspx">社教专题</a><br><a href="https://www.tjbwg.com/cn/News.aspx?TypeId=10975">网络教育</a><br><a href="https://www.tjbwg.com/cn/News.aspx?TypeId=10945">文博讲堂</a><br><a href="https://www.tjbwg.com/cn/Joins.aspx">志愿者基地</a><br><a href="https://www.tjbwg.com/cn/News.aspx?TypeId=10979">天博走出去</a><br></div>
                    <div class="item"><h5>互动</h5><a href="https://www.tjbwg.com/cn/mailbox.aspx">馆长信箱</a><br><a href="https://www.tjbwg.com/cn/feedback.aspx">公众留言</a><br><a href="https://www.tjbwg.com/cn/surveylist.aspx">民意调查</a><br><a href="https://www.tjbwg.com/cn/Events.aspx?TypeId=10962">天博文创</a><br><a href="https://www.tjbwg.com/cn/about.aspx?TypeId=11063">纪检监察信访举报</a><br><a href="https://www.tjbwg.com/cn/about.aspx?TypeId=11067">不忘初心牢记使命</a><br></div>
                    <div class="item"><h5>百年</h5><a href="https://www.tjbwg.com/cn/AnniversaryList.aspx?TypeId=10964">历史回眸</a><br><a href="https://www.tjbwg.com/cn/character.aspx">世纪风骨</a><br><a href="https://www.tjbwg.com/cn/AnniversaryList.aspx?TypeId=10966">百年遗珍</a><br><a href="https://www.tjbwg.com/cn/AnniversaryList.aspx?TypeId=10967">岁月鎏金</a><br></div>
                </div>
                <div class="qr_f">
                    <div class="list">
                    <img src="../../assets/ewm1.jpg" width="62" height="62">
                    <div class="tit">微信公众号</div>
                    </div>
                    <div class="list">
                    <img src="../../assets/ewm2.jpg" width="62" height="62">
                    <div class="tit">天博播报</div>
                    </div>
                    <div class="list">
                    <img src="../../assets/ewm3.jpg" width="62" height="62">
                    <div class="tit">官方微博</div>
                    </div>
                    <div class="list">
                    <img src="../../assets/ewm4.jpg" width="62" height="62">
                    <div class="tit">头条</div>
                    </div>
                    <div class="list">
                    <img src="../../assets/ewm5.jpg" width="62" height="62">
                    <div class="tit">抖音号</div>
                    </div>
                    <div class="list">
                    <img src="../../assets/ewm6.jpg" width="62" height="62">
                    <div class="tit">快手</div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                <div class="contact_f">
                <div class="volc"><img src="../../assets/cs01.png">电话：022-83883000</div>
                <div class="volc"><img src="../../assets/cs02.png">传真：022-83883088</div>
                <div class="volc"><img src="../../assets/cs03.png">地址：天津市河西区平江道62号</div>
                <div class="tboe"><img src="../../assets/mpt.png"></div>
                </div>
            </div> -->
        </div>
        <div class="foot-bottom">
            &copy; 1998-2018天津博物馆版权所有 津ICP备12002929号 技术支持：三五互联
        </div>
    </div>
</template>
<script>
export default {
    name: 'foot',
    data() {
        return{

        }
    }
}
</script>
<style scoped>
    .foot img {width: 100%;}
    .foot {
        background: #790000;
    }
    .foot-bottom {
        height: 56px;
        line-height: 56px;
        background: #171717;
        text-align: center;
        color: #666;
    }
    .footer{ color:#fff; background: url('../../assets/bottom.jpg'); height: 330px;}
    /* .mauto {
    max-width: 1380px;
    margin: 0 auto;
}
h5 {color: #fff;}
.footer a{ color:#fff;}
.footer1{ padding:45px 0 40px;background:#830E0E;}
.footer2{ text-align:center; font-size:90%; background:#171717; color:#666; line-height:1.5; padding:18px 0;}
.link_a{ float:left; width:55%; position:relative;}
.link_a .item{ width:16.5%; float:left; text-align:center;}
.link_a .item h5{ margin-bottom:14px;font-size:22px; line-height:1.3;}
.link_a .item a{ line-height:2; display:inline-block; }
.qr_f{ float:left; width:23%; position:relative; margin-top:5px; text-align:center; display: flex; flex-wrap: wrap; justify-content: center;}
.qr_f::before{ content:''; position:absolute; top:0; left:0; height:100%; width:1px; border-right:1px dashed #B56E6E;}
.qr_f::after{ content:''; position:absolute; top:0; right:0; height:100%; width:1px; border-right:1px dashed #B56E6E;}
.list img {width: 62px;height: 62px;}
.list {width: 30%; text-align: center;}
.footer .share{ margin-top:20px;}
.footer .share a{ vertical-align:middle; display:inline-block; *display:inline; *zoom:1; width:24px; height:20px; margin-left:6px;position:relative;}
.footer .share a i{ display:inline-block; *display:inline; *zoom:1; width:24px; height:20px; }
.footer .share a .icon_share1{ background-position:0 0;}
.footer .share a .icon_share2{ background-position:-31px 0;}
.footer .share .pop_qr{position:absolute; z-index:999; left:50%; margin-left:-72px; bottom:20px; width:144px; padding-bottom:18px; display:none; text-align:center;}
.footer .share a:hover .pop_qr{ display:block;}
.footer .share .pop_qr p{font-size:12px; color:#666; margin-bottom:0;}
.footer .share .pop_qr::after{ content:''; position:absolute; bottom:11px; left:50%; margin-left:-8px; width:15px; height:7px; }
.footer .share .pop_qr .pop_qr_in{ background:#fff; padding:15px; }
.contact_f{ float:right; }
.cont_f{ text-align:right;}
.cont_f .tel_num{ font-size:28px; font-weight:bold; font-family:Arial, Helvetica, sans-serif; color:#ccc; vertical-align:middle;}
.cont_f .tel_text{color:#eee; margin:0 0.8em; vertical-align:middle;}
.cont_f p{ margin-bottom:12px;}

.body_lg .footer2{ position:absolute; left:0; right:0; bottom:0;}



.contact_f .volc{ line-height:22px; padding:5px 0;}
.contact_f .volc img{ margin-right:8px;}
.contact_f .volc img {height: 20px; width: 18px;}
.bg-ccl{ display:none; position:fixed; left:0; top:0; width:100%; height:100%; z-index:99; background:rgba(0,0,0,0.7);}
.alotrc{ display:none; position:fixed; text-align:center; z-index:100; left:50%; top:50%; transform:translateY(-50%); width:428px; margin-left:-214px; background:#fff; padding:40px 0;}
.alotrc .tit{ margin-top:12px; font-size:16px; line-height:1.8; color:#333;}
.alotrc .bgc{ margin-top:20px;}

    .list img:hover {
        transform: scale(1.6);
    } */
</style>