<template>
    <div class="list">
        <heads />
        <div class="con-main">
            <div class="hd-navs">
                <a-breadcrumb>
                    <a-breadcrumb-item><a href="./">藏品鉴赏</a></a-breadcrumb-item>
                    <a-breadcrumb-item>文物鉴赏</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="main">
                <h2>{{title}}</h2>
                <div class="img-box">
                    <img :key="item" :src="imgSrc" alt="" class="imglist-item">
                </div>
            </div>
        </div>
        <foots />
    </div>
</template>
<script>
import heads from '../component/heads.vue';
import foots from '../component/foot.vue';
export default {
    components: {heads, foots},
    data() {return {
        title: '',
        list: [
            {title: '五四运动以磅礴之力鼓动了中国人民和中华民族实现民族复兴的志向和信心。', url: require('../../assets/1.png')},
            {title: '第二次国内革命战争时期牺牲的何叔衡烈士遗物——罗大褂 ', url: require('../../assets/2.jpg')},
            {title: '第二次国内革命战争时期牺牲的何叔衡烈士遗物——怀表。', url: require('../../assets/3.jpg')},
            {title: '毛主席在开国大典时穿过的绿呢制服', url: require('../../assets/4.jpg')},
            {title: '天津试制成功的第一只国产手表', url: require('../../assets/6.jpg')},
            {title: '中国人民志愿军一级战斗英雄杨连弟在抗美援朝战场使用的军用工具挎包；', url: require('../../assets/5.jpg')},
        ],
        imgSrc: ''
    }},
    mounted() {
        this.pageInit();
    },
    methods: {
        pageInit() {
            const index = this.$route.query.type || 0;
            this.title = this.list[index].title;
            this.imgSrc = this.list[index].url;
        },
    }
}
</script>

<style scoped>
    .hd-navs {
        width: 1200px;
        margin: 30px auto 0;
    }
    .hd-navs /deep/ .ant-breadcrumb-link a, .hd-navs /deep/ .ant-breadcrumb-link {
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }
    .con-main {
    margin: 0 auto 60px;
    width: 1200px;
    background: #fff;
    box-shadow: 1px 5px 40px rgba(9,2,4,0.1);
    -webkit-box-shadow: 1px 5px 40px rgba(9,2,4,0.1);
    padding: 60px 20px 20px 20px;
}
    .main {
        min-height: 300px;
        width: 1200px;
        margin: 0 auto;
        font-size: 16px;
        padding: 10px 0 80px;
    }
    h2 {
        text-align: center;
        margin: 50px 0;
        font-weight: 600;
    }
    .img-box {
        width: 80%;
        margin: 0 auto;
    }
    .imglist-item {
        width: 100%;
        margin-bottom: 20px;
    }
</style>